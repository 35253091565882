<template>
  <div class="wrap">
    <header class="x-page-title">
      <h3>上架详情</h3>
    </header>

    <main class="x-main">
      <el-button
        icon="el-icon-arrow-left"
        type="primary"
        size="small"
        @click="$router.back()"
        >返回</el-button
      >
      <!-- 标题类型 -->
      <el-card class="mb30">
        <el-form
          class="x-form"
          ref="titleForm"
          size="small"
          label-width="150px"
          :model="form"
        >
          <el-form-item label="所属品牌：" prop="brandID">
            <el-select
              placeholder="请选择所属品牌"
              v-model="form.brandID"
              disabled
            >
              <el-option
                v-for="(item, index) in optionsBrand"
                :key="index"
                :label="item.brandName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="藏品集：" prop="collectionID">
            <el-select
              placeholder="请选择藏品集"
              v-model="form.collectionID"
              disabled
            >
              <el-option
                v-for="(item, index) in optionsCollection"
                :key="index"
                :label="item.collectionName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="藏品ID：" prop="NFTID">
            <el-select
              disabled
              placeholder="请输入藏品ID"
              filterable
              :clearable="true"
              remote
              reserve-keyword
              :remote-method="doIdQuery"
              :loading="loading"
              v-model="form.NFTID"
              @change="setBaseFormOpenId"
            >
              <el-option
                v-for="item in optionId"
                :key="item.id"
                :label="item.id"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="藏品类型：" prop="sellType">
            <el-select
              placeholder="请选择藏品类型"
              v-model="form.sellType"
              disabled
            >
              <el-option label="普通商品" value="1"></el-option>
              <el-option label="非卖品" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上架时间：" prop="onlineTime">
            <el-date-picker
              disabled
              v-model="form.onlineTime"
              type="datetime"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="开售时间：" prop="onsellTime">
            <el-date-picker
              disabled
              v-model="form.onsellTime"
              type="datetime"
              placeholder="选择日期时间"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="藏品名称：" prop="NFTName">
            <el-input v-model="form.NFTName" disabled></el-input>
          </el-form-item>
          <el-form-item label="上架数量：" prop="onlineCount">
            <el-input v-model="form.onlineCount" disabled></el-input>
          </el-form-item>
          <el-form-item label="单笔最大购买数量:" prop="buyCountOnce">
            <el-input
              v-model="form.buyCountOnce"
              type="number"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="单价(单位:元)：" prop="unitPrice">
            <el-input v-model="form.unitPrice" disabled></el-input>
          </el-form-item>
          <el-form-item label="单用户最大购买数量:" prop="maxCountPerUser">
            <el-input
              v-model="form.maxCountPerUser"
              type="number"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="是否新人专享:" prop="isNewUserBuy">
            <el-radio
              v-model="form.isNewUserBuy"
              :label="isNewUserBuyTrue"
              disabled
              >是</el-radio
            >
            <el-radio
              v-model="form.isNewUserBuy"
              :label="isNewUserBuyFalse"
              disabled
              >否</el-radio
            >
          </el-form-item>
          <el-form-item
            label="用户注册时间:"
            prop="isNewUserBuyTime"
            v-if="form.isNewUserBuy === true"
          >
            <el-date-picker
              v-model="form.isNewUserBuyTime"
              type="datetimerange"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="doChange"
              disabled
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </el-card>
      <div class="x-q-imgTip">藏品图片或3D模型：</div>
      <div class="x-q-img">
        <el-image
          v-if="imgUrl !== '' || imgUrl === undefined"
          style="width: 100%; height: 100%; border-radius: 10px"
          :src="imgUrl"
          :fit="contain"
        ></el-image>
        <span v-else>输入藏品ID后可查看</span>
      </div>
    </main>
  </div>
</template>

<script>
import { BrandQuery } from "@/api/module/brand";
import { CollectionQuery } from "@/api/module/collection";
import { MarketDetail } from "@/api/module/market";

export default {
  name: "MarketDetail",
  props: ["id"],
  data() {
    return {
      isNewUserBuyFalse: false,
      isNewUserBuyTrue: true,
      treeImgList: [],
      optionsBrand: [],
      optionsCollection: [],
      form: {
        isNewUserBuyTime: [],
        isNewUserBuy: "",
        maxCountPerUser: "",
        brandID: "",
        NFTName: "",
        collectionID: "",
        NFTID: "",
        onlineCount: "",
        unitPrice: "",
        onlineTime: "",
        onsellTime: "",
        sellType: "",
        buyCountOnce: "",
      },
      imgUrl: "",
    };
  },
  created() {
    this.getPackage();
    this.getDetail();
  },
  methods: {
    async getDetail() {
      const r = await MarketDetail(this.id);
      this.form = {
        brandID: r.brandID,
        NFTName: r.NFTName,
        collectionID: r.collectionID,
        NFTID: r.NFTID,
        onlineCount: r.onlineCount,
        unitPrice: r.unitPrice,
        onlineTime: r.onlineTime,
        onsellTime: r.onsellTime,
        treeImg: r.posterUrl,
        sellType: r.sellType,
        buyCountOnce: r.buyCountOnce,
        isNewUserBuyTime: [r.regStartTime, r.regEndTime],
        isNewUserBuy: r.isNewUserBuy,
        maxCountPerUser: r.maxCountPerUser,
      };
      this.imgUrl = r.NFTUrl;
    },

    // 获取选项
    async getPackage() {
      const rb = await BrandQuery(`?pagination=false&current=1&pageSize=10`);
      const rc = await CollectionQuery(
        `?pagination=false&current=1&pageSize=10`
      );
      this.optionsCollection = rc.list;
      this.optionsBrand = rb.list;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-card {
  margin-top: 15px;
}
.x-main {
  position: relative;
  .x-q-imgTip {
    position: absolute;
    top: 187px;
    left: 470px;
  }
  .x-q-img {
    text-align: center;
    line-height: 180px;
    background-color: rgb(243, 241, 241);
    position: absolute;
    border-radius: 5px;
    top: 134px;
    left: 616px;
    width: 360px;
    height: 180px;
    border: 1px dashed rgb(223, 220, 220);
  }
}
</style>